<style lang="less">
.app-home-vue {
  .home-part-body {
    height: 350px;
    .echarts-vue {
      height: 350px;
    }
  }

  .home-part-body2 {
    height: 420px;
    .echarts-vue {
      height: 420px;
    }
  }

  .progress-div {
    > p {
      padding: 8px 0;
    }
    .h-progress {
      &-title {
        color: @dark2-color;
        font-size: 15px;
      }
      &-text {
        width: 40px;
      }
    }
  }
}
.carousel-demo1 .h-carousel-item {
  background: @dark-color;
  .h-carousel-p {
    // color: #fff;
	color: #000;
    font-size: 20px;
	padding-top: 15px;
    // padding-top: 120px;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
</style>

<template>
  <div class="app-home-vue frame-page">
    <Row :space="30">
		<Cell :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="h-panel">
          <div class="h-panel-bar">
            <div class="h-panel-title">今日修仙次数</div>
            <div class="h-panel-right">
              <span class="gray-color">总共达成</span><Space /><span class="font20 primary-color">{{user.count.z_order}}</span><Space /><span class="gray-color">次数</span>
            </div>
          </div>
          <div class="h-panel-body">
		<h1>{{user.count.j_order}}</h1>
          </div>
        </div>
		</Cell>
		<Cell :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="h-panel">
          <div class="h-panel-bar">
            <div class="h-panel-title">我的道友</div>
            <div class="h-panel-right">
              <span class="gray-color">今日登录</span><Space /><span class="font20 primary-color">{{user.count.dl_dl}}</span><Space /><span class="gray-color">个</span>
            </div>
          </div>
          <div class="h-panel-body">
		<h1>{{user.count.dl_num}}</h1>
          </div>
        </div>
		</Cell>		
		<Cell :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="h-panel">
          <div class="h-panel-bar">
            <div class="h-panel-title">我的灵石</div>
<!--            <div class="h-panel-right">
              <span class="gray-color">总共达成</span><Space /><span class="font20 primary-color">200</span><Space /><span class="gray-color">W</span>
            </div> -->
          </div>
          <div class="h-panel-body">
				<h1>{{user.money}}</h1>
          </div>
        </div>
		</Cell>		
      <Cell :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="h-panel">
          <div class="relative">
			  <Carousel class="carousel-demo1" :datas="params"
			        :height="60"
			        :autoplay="autoplay"
			        :paginationTrigger="paginationTrigger"
			        :arrow="arrow"
			        :pageTheme="pageTheme"
			        :speed="3000"
			        :changeSpeed="1000"
			        :effect="effect"
			      >
				        <template v-slot:item="{ carousel }">
				          <div class="h-carousel-p" :style="`background: ${carousel.color}`">{{ carousel.title }}</div>
				        </template>
				  </Carousel>
          </div>
        </div>
      </Cell>
      <Cell :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="h-panel">
          <div class="h-panel-bar">
            <div class="h-panel-title">通知信息</div>
<!--            <div class="h-panel-right">
              <span class="gray-color">总共达成</span><Space /><span class="font20 primary-color">200</span><Space /><span class="gray-color">W</span>
            </div> -->
          </div>
          <div class="h-panel-body progress-div home-part-body">
			  <Timeline>
				<TimelineItem color="green" v-for="(item,index) in notice" :key="index"><div v-html="item"></div></TimelineItem>
			  </Timeline>
          </div>
        </div>
      </Cell>
<!-- 
      <Cell :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
        <div class="h-panel">
          <div class="h-panel-bar">
            <div class="h-panel-title">数据比例</div>
          </div>
          <div class="home-part-body2">
            <Chart :options="data2"></Chart>
          </div>
        </div>
      </Cell> -->

<!--      <Cell :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
        <div class="h-panel">
          <div class="h-panel-bar">
            <div class="h-panel-title">数据比例</div>
          </div>
          <div class="h-panel-body home-part-body2">
            <Row :space="20">
              <Cell :width="10" class="text-right">
                <h-circle :percent="76" :stroke-width="10" :size="90">
                  <p>
                    <span class="font28">{{ parseInt((123 * 76) / 100) }}</span
                    ><span class="gray-color"> / 123</span>
                  </p>
                </h-circle>
              </Cell>
              <Cell :width="14">
                <p class="gray-color">目前达成比例</p>
                <p class="dark-color font22">122,332,98</p>
              </Cell>
              <p class="clearfix"></p>
              <Cell :width="10" class="text-right">
                <h-circle :percent="99" :stroke-width="10" :size="90" color="green">
                  <p>
                    <span class="font28">{{ parseInt((123 * 76) / 100) }}</span
                    ><span class="gray-color"> / 123</span>
                  </p>
                </h-circle>
              </Cell>
              <Cell :width="14">
                <p class="gray-color">目前达成比例</p>
                <p class="dark-color font22">122,332,98</p>
              </Cell>
              <p class="clearfix"></p>
              <Cell :width="10" class="text-right">
                <h-circle :percent="22" :stroke-width="10" :size="90" color="red">
                  <p>
                    <span class="font28">{{ parseInt((123 * 76) / 100) }}</span
                    ><span class="gray-color"> / 123</span>
                  </p>
                </h-circle>
              </Cell>
              <Cell :width="14">
                <p class="gray-color">目前达成比例</p>
                <p class="dark-color font22">122,332,98</p>
              </Cell>
            </Row>
          </div>
        </div>
      </Cell> -->
    </Row>
  </div>
</template>
<script>
import data1 from '@components/demo-components/components/datas/data1';
import data2 from '@components/demo-components/components/datas/data2';
import data3 from '@components/demo-components/components/datas/data4';
import utils from '@common/utils';
export default {
  data() {
    return {
      data1,
      data2,
      data3,
      type: 'type1',
		autoplay: true,
		pageTheme: 'square',
		arrow: 'hover',
		paginationTrigger: 'click',
		effect: 'scroll',
		pageThemes: { circle: '圆形', square: '方形', hidden: '隐藏' },
		arrows: { hidden: '隐藏', always: '一直显示', hover: '悬停' },
		paginationTriggers: { click: '点击', hover: '悬停' },
		effects: { fade: '淡入淡出', scroll: '滚动' },
	    params: [
			// { title: '报国嫌言利，修真笑炼丹。', color: '#d36eb0' },
			// { title: '山林深处掩柴扉，尘世纷扰不相闻', color: '#7276f5' },
			// { title: '炼气打坐悟玄机，金丹元婴自兹生。', color: '#b6cf4d' },
			// { title: '仙山之巅一盏灯,独坐凌空万古情。', color: '#cf5e4d' },
			// { title: '青春人依旧，只叹时光太匆匆！', color: '#d69eb0' },
			{ title: '天地合一通无碍,修行问道心灵间！', color: '#fff' },
			{ title: '得道年来八百秋，不曾飞剑取人头', color: '#fff' },
			{ title: '仙人语我 自史今千载，疑人或有仙。', color: '#fff' },
			// { title: '坚心一志任前程，大道于人终不负。', color: '#fff' },
			// { title: '飘然乘云气，俯首视世寰！', color: '#fff' },
	    ],
		notice:[],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    siderCollapsed: {
      get() {
        return this.$store.state.siderCollapsed;
      },
      set(value) {
        this.$store.commit('updateSiderCollapse', value);
      }
    }
  },
  mounted() {
  	   this.notice=this.user.notice.split("<hr>")
  },
  methods: {
    openMore() {
      this.$router.push({ name: 'Chart' });
    }
  }
};
</script>
